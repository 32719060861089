import React, { useEffect, useRef } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { useAuth } from '../../../../store/AuthProvider';

type Props = {
  className: string;
  data: Array<Object> | Object | undefined;
};
export default function DeviceComparison({ className, data }: Props) {
  const { user } = useAuth();
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const height = 350;

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, data)
    );
    if (chart) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef]);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">
            Comparison by{' '}
            {user!.role === 'institution_head' ? 'department' : 'office'}
          </span>

          {/* <span className='text-muted fw-bold fs-7'>More than 500 new orders</span> */}
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body py-0">
        {/* begin::Chart */}
        <div
          ref={chartRef}
          id="kt_charts_widget_2_chart"
          style={{ height: '320px' }}
        ></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
}

function getChartOptions(height: number, data: any): ApexOptions {
  const labelColor = '#e3dddc';
  const borderColor = '#e3dddc';
  const baseColor = '#f2c035';

  return {
    series: [
      {
        name: '',
        data,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '90%',
        borderRadius: 2,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: data?.map((value: any) => value.x),
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          let rating;
          if (val >= 80) {
            rating = 'Very good';
          } else if (val >= 60) {
            rating = 'Good';
          } else if (val >= 40) {
            rating = 'Average';
          } else if (val >= 20) {
            rating = 'Bad';
          } else {
            rating = 'Very Bad';
          }
          return rating + ' ' + val + '%';
        },
      },
    },
    colors: [baseColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
}
