import React from 'react';
import CitizenExperiences from './components/CitizenExperiences';
import BarCharDisplay from './components/BarCharDisplay';
import PieCharDisplay from './components/PieChartDisplay';
import ChartMetricsLoader from './components/Loader/ChartMetricsLoader';

type Props = {
  YesNoQuery: any;
};
export default function ChartMetrics({ YesNoQuery }: Props) {
  const { isLoading, isFetching, isSuccess } = YesNoQuery;
  if (isLoading || isFetching) {
    return <ChartMetricsLoader />;
  }
  return (
    <div className="px-4 chart-metrics">
      {isSuccess ? (
        <div>
          <CitizenExperiences
            title="Citizen Experiences"
            YesNoQuery={YesNoQuery}
          />
        </div>
      ) : null}

      {/* {isSuccess ? (
        <div>
          <BarCharDisplay title="Bar Chart" />
        </div>
      ) : null} */}

      {/* {isSuccess ? (
        <div>
          <PieCharDisplay title="Pie Chart" />
        </div>
      ) : null} */}
    </div>
  );
}
