/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './core/style.scss';

export default function NotFound() {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404"></div>
        <h1>404</h1>
        <h2>Data for selected period not found</h2>
        <p>Sorry but the data you are looking for does not exist, or has not yet been uploaded</p>
      </div>
    </div>
  );
}
