import React from "react";
import { Skeleton } from "antd";

export default function StatisticCardLoader() {
  return (
    <div className="card">
      <div className=" card-body d-flex justify-content-between align-items-center">
        <div>
          <h2>
            <b>
              <Skeleton.Button active={true} />
            </b>
          </h2>
          <Skeleton.Input active={true} />
        </div>
        <div>
          <Skeleton.Avatar active={true} shape="circle" />
        </div>
      </div>
    </div>
  );
}
