import React from 'react';

import AppButtonOutline from '../../../sharedcomponents/Buttons/AppButtonOutline/AppButtonOutline';
import AddDepartmentHeadForm from './components/AddDepartmentHeadForm';
import DepartmentHeadList from './components/DepartmentHeaadList.tsx/DepartmentHeadList';
import { useSettingPage } from '../../../../store/SettingsProvider';
import DeleteDepartmentHead from './components/DeleteDepartmentHead';

export default function UserManagement() {
  const { setDepartmentHead } = useSettingPage();
  return (
    <div>
      <div className="d-flex justify-content-between pt-4">
        <h2>User Management</h2>
        <AppButtonOutline
          text="Add User"
          btnType="success"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#addDepartmentHead"
          onClick={() => {
            setDepartmentHead({
              action: 'create',
              data: {
                name: '',
                email: '',
                phoneNumber: '',
                department: '',
              },
            });
          }}
        />
      </div>
      <AddDepartmentHeadForm />
      <DepartmentHeadList />
      <DeleteDepartmentHead />
    </div>
  );
}
