import React from 'react';
import clsx from 'clsx';
import { Tooltip } from 'react-tooltip';
import { ResendInvite } from '../../../../../../../apis/app.service';

import { useSettingPage } from '../../../../../../../store/SettingsProvider';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';

interface Props {
  user: any;
}
export default function DepartmentHeadCard({ user }: Props) {
  const { setDepartmentHead } = useSettingPage();

  const mutation = useMutation({
    mutationFn: ResendInvite,
    onSuccess: (res) => {
      const { message } = res;
      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });

  return (
    <div>
      <div className="card my-3">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div style={{ flexBasis: '40%' }}>
              <h5 className="card-title">{user.email}</h5>
              <p className="card-text">
                <small className="d-block text-body-secondary">
                  <span className="fw-bold">Name:</span> {user.name}
                </small>
                <small className="d-block text-body-secondary">
                  <span className="fw-bold">Phone:</span> {user.phoneNumber}
                </small>
                <small className="d-block text-body-secondary">
                  <span className="fw-bold">Creation Date:</span>{' '}
                  {new Date(user.createdAt).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  })}
                </small>
              </p>
            </div>
            <div className="align-self-center" style={{ flexBasis: '25%' }}>
              <h6 className="card-title">{user.department.join(', ')}</h6>
            </div>
            <div className="align-self-center" style={{ flexBasis: '15%' }}>
              <h6
                className={clsx('card-title dhc_account_state', {
                  dhc_account_state_active: user.active,
                  dhc_account_state_pending: !user.active,
                })}
              >
                {user.active ? 'Active' : 'Pending'}
              </h6>
            </div>
            <div className="align-self-center" style={{ flexBasis: '20%' }}>
              <span data-bs-toggle="modal" data-bs-target="#addDepartmentHead">
                <i
                  className="fa-solid fa-pencil fs-4 me-4"
                  data-tooltip-id="tooltip"
                  data-tooltip-content="edit"
                  onClick={() => {
                    const data = {
                      id: user._id,
                      name: user.name,
                      email: user.email,
                      phoneNumber: user.phoneNumber.slice(4),
                      department: user.department.join(','),
                    };
                    setDepartmentHead({ action: 'edit', data });
                  }}
                ></i>
              </span>

              <i
                className={clsx('fa-regular fa-envelope fs-4 me-4', {
                  dhc_email_inactive: user.active,
                  dhc_icon_color: !user.active,
                })}
                data-tooltip-id="tooltip"
                data-tooltip-content="resend invite"
                onClick={() => {
                  if (!user.active) {
                    mutation.mutate({ email: user.email });
                  }
                }}
              ></i>
              <i
                className="fa-solid fa-trash fs-4 me-4"
                data-bs-toggle="modal"
                data-bs-target="#deleteDepartmentHead"
                data-tooltip-id="tooltip"
                data-tooltip-content="delete"
                onClick={() => {
                  const data = {
                    id: user._id,
                    name: user.name,
                    email: user.email,
                    phoneNumber: user.phoneNumber.slice(4),
                    department: user.department,
                  };
                  setDepartmentHead({ action: 'delete', data });
                }}
              ></i>
            </div>
          </div>
        </div>
      </div>
      <Tooltip id="tooltip" />
    </div>
  );
}
