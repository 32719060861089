/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { toAbsoluteUrl } from "../../../../../helpers/AssetHelpers";

type Props = {
  title: string;
  body: string;
  icon: string;
};

export default function TextCard({ title, body, icon }: Props) {
  return (
    <div className="card h-100" style={{ pageBreakInside: "avoid" }}>
      <div className="card-header pt-2">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fs-3 mb-1 fw-bold">{title}</span>
        </h3>
      </div>
      <div
        className="card-body d-flex align-items-center justify-content-between"
        style={{ columnGap: "1rem" }}
      >
        <img
          src={toAbsoluteUrl(icon)}
          alt="SEMA logo"
          className="align-self-center mb-2"
          height="50px"
        />
        <span>{body}</span>
      </div>
    </div>
  );
}
