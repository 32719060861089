import { APICore } from './api.core';
import { SignInForm } from '../pages/Auth/core/models';
import { InterviewFilters } from '../pages/Interviews/core/models';
import {
  IChangePassword,
  IDepartmentHeadPayload,
  UserProfileUpdate,
} from '../pages/Settings/core/models';
import { PasswordReset } from '../pages/SetPassword/core/models';
import { IDeviceDataFilters } from '../pages/Devices/core/models';

const api = new APICore();
// ================ begin auth endpoints ===================

export const SignIn = async (body: SignInForm) => {
  const response = await api.postAPI('/api/user/login', body);

  const { status, message, data } = response.data;
  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

export const ResetPassword = async ({
  resetToken,
  body,
}: {
  resetToken: string;
  body: PasswordReset;
}) => {
  const response = await api.patchAPI(
    `/api/user/reset-password/${resetToken}`,
    body
  );

  const { status, message, data } = response.data;
  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

export const ResendInvite = async (body: any) => {
  const response = await api.patchAPI(`/api/user/resend-invite`, body);

  const { status, message, data } = response.data;
  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

export const ProfileUpdate = async (body: UserProfileUpdate) => {
  const response = await api.patchAPI('/api/user/updateProfile', body);

  const { status, message, data } = response.data;
  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

export const PasswordUpdate = async (body: IChangePassword) => {
  const response = await api.patchAPI('/api/user/updatePassword', body);

  const { status, message, data } = response.data;
  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

export const deleteAccount = async () => {
  const response = await api.deleteAPI('/api/user/deleteAccount');

  const { status, message, data } = response.data;
  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

export const deleteUser = async (userId: string) => {
  const { status, message } = await api
    .deleteAPI(`/api/user/${userId}`)
    .then((res) => res.data);

  if (status !== 'success') {
    throw new Error(message);
  }

  return { message };
};

// ================ end auth endpoints ===================

// ================ Begin device endpoints ===================
export const getFilters = async () => {
  const { status, message, data } = await api
    .getAPI(`/api/statistic/deviceFilters`)
    .then((res) => res.data);

  if (status !== 'success') {
    throw new Error(message);
  }

  return { message, data };
};

export const getDeviceStats = async (query?: IDeviceDataFilters) => {
  const response = await api
    .getAPI('/api/statistic/deviceStatistics', { params: { ...query } })
    .then((res) => res.data);

  const { status, message, data } = response;
  if (status !== 'success') {
    throw new Error(message);
  }

  return { data };
};
// ================ End device endpoints ===================

// ================ begin interview endpoints ==================

export const getCardStatistics = async (params: InterviewFilters) => {
  const { status, message, data } = await api
    .getAPI('/api/statistic/cardStatistics', { params })
    .then((res) => res.data);

  if (status !== 'success') {
    throw new Error(message);
  }

  return { message, data };
};

export const getYesNoStatistics = async (params: InterviewFilters) => {
  const { status, message, data } = await api
    .getAPI('/api/statistic/yesNoStatistics', { params })
    .then((res) => res.data);

  if (status !== 'success') {
    throw new Error(message);
  }

  return { message, data };
};

export const getTextStatistics = async (params: InterviewFilters) => {
  const { status, message, data } = await api
    .getAPI('/api/statistic/textStatistics', { params })
    .then((res) => res.data);

  if (status !== 'success') {
    throw new Error(message);
  }

  return { message, data };
};

export const getOldStatistics = async (params: InterviewFilters) => {
  const { status, message, data } = await api
    .getAPI('/api/statistic/interviewStatistics', { params })
    .then((res) => res.data);

  if (status !== 'success') {
    throw new Error(message);
  }

  return { message, data };
};

// ================ end interview endpoints ===================

// ================ begin report endpoints ===================
export const getReportData = async (params: { recipientIds: string }) => {
  const { status, message, data } = await api
    .getAPI('/api/report', { params })
    .then((res) => res.data);

  if (status !== 'success') {
    throw new Error(message);
  }

  return data.docs;
};

// ================ end report endpoints ===================

export const SCreateDepartmentHead = async (body: IDepartmentHeadPayload) => {
  const { status, message, data } = await api
    .postAPI('/api/user', body)
    .then((res) => res.data);
  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

export const SUpdateDepartmentHead = async (update: {
  userId: string;
  body: IDepartmentHeadPayload;
}) => {
  const { status, message, data } = await api
    .patchAPI(`/api/user/${update.userId}`, update.body)
    .then((res) => res.data);
  if (status !== 'success') {
    throw new Error(message);
  }
  return { message, data };
};

export const SGetUserByRole = async () => {
  const { status, message, data } = await api
    .getAPI('/api/user/external/department_head')
    .then((res) => res.data);

  if (status !== 'success') {
    throw new Error(message);
  }

  return data.docs;
};

export const SGetUsers = async (id: string, params?: any) => {
  const { status, message, data } = await api
    .getAPI(`/api/user/${id}/external`, { params })
    .then((res) => res.data);

  if (status !== 'success') {
    throw new Error(message);
  }

  return data.docs;
};
