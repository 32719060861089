import React from 'react';
import { BeatLoader } from 'react-spinners';

import { toAbsoluteUrl } from '../../helpers/AssetHelpers';

export default function LoadingSpinner() {
  return (
    <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
      <img
        src={toAbsoluteUrl('media/logos/sema-logo.png')}
        alt="SEMA logo"
        className="align-self-center mb-2"
      />
      <BeatLoader className="align-self-center" color="#07031FF9" size={10} />
    </div>
  );
}
