import React from 'react';

import { useQuery } from '@tanstack/react-query';

import DepartmentHeadCard from './components/DepartmentHeadCard';
import { SGetUsers } from '../../../../../../apis/app.service';
import './core/style.scss';
import { useAuth } from '../../../../../../store/AuthProvider';

export default function DepartmentHeadList() {
  const { user } = useAuth();
  const { data } = useQuery({
    queryKey: ['DepartmentHeadList'],
    queryFn: () =>
      SGetUsers(user!.id, { role: 'department_head', parent: user!.id }),
  });

  return (
    <div>
      <div className="d-flex mb-2 px-3">
        <div style={{ flexBasis: '40%' }}>
          <h4 className="card-title">Details</h4>
        </div>
        <div style={{ flexBasis: '25%' }}>
          <h4 className="card-title">Department</h4>
        </div>
        <div style={{ flexBasis: '15%' }}>
          <h4 className="card-title">State</h4>
        </div>
        <div style={{ flexBasis: '20%' }}>
          <h4 className="card-title">Actions</h4>
        </div>
      </div>
      {data
        ? data.map((user: any) => <DepartmentHeadCard user={user} />)
        : null}
    </div>
  );
}
