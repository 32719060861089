/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';

interface Props {
  className: string;
  title: string;
  series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
  categories: Array<string>;
}

const PercentageDistButtonPresses: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  title,
  series,
  categories,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(series, categories)
    );
    if (chart) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, series, categories]);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">{title}</span>
        </h3>

        <div className="card-toolbar" data-kt-buttons="true"></div>
      </div>

      <div className="card-body">
        <div
          ref={chartRef}
          id="kt_charts_widget_6_chart"
          style={{ height: '350px' }}
        ></div>
      </div>
    </div>
  );
};

export { PercentageDistButtonPresses };

function getChartOptions(
  series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined,
  categories: Array<string>
): ApexOptions {
  const labelColor = '#e3dddc';
  const borderColor = '#e3dddc';

  return {
    series,
    chart: {
      type: 'bar',
      fontFamily: 'inherit',
      stacked: true,
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth: '95%',
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      // curve: "smooth",
      // show: true,
      width: 2,
      colors: ['#fff'],
    },
    xaxis: {
      categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      max: 100,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + '%';
        },
      },
    },
    colors: ['#DB2524', '#EA8C28', '#CDCF00', '#7EB147', '#00803D'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  };
}
