import React from "react";
import TextCardLoader from "./TextCardLoader";

export default function TextMetricsLoader() {
  return (
    <div className="px-4 text-metrics">
      <TextCardLoader />
      <TextCardLoader />
    </div>
  );
}
