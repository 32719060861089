import React, { createContext, useContext, useState, ReactNode } from 'react';
import { SettingContextType } from './core/models';
import { IDepartmentHead } from '../pages/Settings/core/models';

// Create Context
const SettingContext = createContext<SettingContextType | undefined>(undefined);

// Create a Provider Component
function SettingProvider({ children }: { children: ReactNode }) {
  const [departmentHead, setDepartmentHead] = useState<IDepartmentHead>({
    action: 'create',
    data: {
      name: '',
      email: '',
      phoneNumber: '',
      department: '',
    },
  });

  return (
    <SettingContext.Provider
      value={{
        departmentHead,
        setDepartmentHead,
      }}
    >
      {children}
    </SettingContext.Provider>
  );
}

// Custom hook for using the context
function useSettingPage() {
  const context = useContext(SettingContext);
  if (!context) {
    throw new Error('useSettingPage must be used within an SettingProvider');
  }
  return context;
}

export { SettingProvider, useSettingPage };
