import React from 'react';

type Props = {
  className: string;
  title: string;
  statistics?: Object;
};

export default function CitizenExperiences({
  className,
  title,
  statistics,
}: Props) {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">{title}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted bg-light">
                <th className="ps-4 min-w-320px rounded-start"></th>
                <th className="min-w-130px pe-4 text-end">
                  Average satisfaction
                </th>
                <th className="min-w-130px pe-4 text-end">
                  Average Waiting Time
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {statistics !== undefined
                ? Object.entries(statistics).map(([key, value]) => (
                    <tr key={key}>
                      <td className="ps-4">
                        <span className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-8">
                          {key}
                        </span>
                      </td>
                      <td className="pe-4 text-center">
                        <span className="text-success fw-bolder text-hover-primary d-block mb-1 fs-8">
                          {value.averageSatisfaction}%
                        </span>
                      </td>
                      <td className="pe-4 text-center">
                        <span className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-8">
                          {value.waitingTime} mins
                        </span>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
}
