import React from 'react';
import TextCard from './components/TextCard';
import TextMetricsLoader from './components/Loader/TextMetricsLoader';

interface Props {
  textStatisticsQuery: any;
}

export default function TextMetrics({ textStatisticsQuery }: Props) {
  const {
    data: response,
    isLoading,
    isFetching,
    isSuccess,
  } = textStatisticsQuery;

  const textStatistics = response?.data;

  if (isLoading || isFetching) {
    return <TextMetricsLoader />;
  }
  return (
    <div>
      {isSuccess ? (
        <div className="px-4 text-metrics">
          {textStatistics?.['Quote'] ? (
            <div>
              <TextCard
                title="Monthly Quote"
                body={`${textStatistics['Quote']}`}
                icon="media/icons/comment.svg"
              />
            </div>
          ) : null}

          {textStatistics?.['Comment'] ? (
            <div>
              <TextCard
                title="Monthly Comment"
                body={`${textStatistics['Comment']}`}
                icon="media/icons/gear.svg"
              />
            </div>
          ) : null}

          {textStatistics?.['ClientFeedback'] ? (
            <div>
              <TextCard
                title="Monthly Client Response"
                body={
                  'During this month, majority of the citizens complained of spending alot of time at the police station than was required'
                }
                icon="media/icons/gear.svg"
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
