import React from 'react';
import './core/style.scss';

// primary Submit/Save #007bff
// secondary Cancel/Back #6c757d
// success Add #ff9933
// danger Delete #dc3545
// warning Proceed with Caution #ffc107
// infoMore Info #17a2b8

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  width?: string;
  btnType?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info';
}

export default function AppButtonOutline({
  text,
  btnType = 'primary',
  width = '120px',
  ...rest
}: Props) {
  return (
    <button
      type="button"
      className={`btn mb-2 mb-md-0 btn-block appbtnoutline-${btnType} rounded-4`}
      style={{ width }}
      {...rest}
    >
      {text}
    </button>
  );
}
