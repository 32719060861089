import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import MasterLayout from '../layout/Master/MasterLayout';
import AuthLayout from '../layout/Auth/AuthLayout';

import InterviewsPage from '../pages/Interviews/InterviewsPage';
import ReportsPage from '../pages/Reports/ReportsPage';
import ErrorPage from '../pages/Error/ErrorPage';
import AuthPage from '../pages/Auth/AuthPage';
import SettingsPage from '../pages/Settings/SettingsPage';
import Profile from '../pages/Settings/components/Profile/Profile';
import UserManagement from '../pages/Settings/components/UserManagement/UserManagement';
import SetPassword from '../pages/SetPassword/SetPassword';
import Home from '../pages/Home/Home';
import DevicePage from '../pages/Devices/DevicePage';

export default function PrivateRoutes() {
  return (
    <Routes>
      <Route path="/auth" element={<AuthLayout />}>
        <Route path="set-password/:reset_token" element={<SetPassword />} />
        <Route path="" element={<AuthPage />} />
      </Route>
      <Route path="/" element={<MasterLayout />}>
        <Route path="interviews" element={<InterviewsPage />} />
        <Route path="devices" element={<DevicePage />} />
        <Route path="reports" element={<ReportsPage />} />
        <Route path="settings/" element={<SettingsPage />}>
          <Route path="profile" element={<Profile />} />
          <Route path="user-management" element={<UserManagement />} />
        </Route>
        <Route index element={<Navigate to="interviews" />} />
      </Route>
      <Route path="*" element={<ErrorPage goToPage="/interviews" />} />
    </Routes>
  );
}
