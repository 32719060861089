/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

type Props = {
  title: string;
  YesNoQuery: any;
};

export default function CitizenExperiences({ title, YesNoQuery }: Props) {
  const { data: response, isSuccess } = YesNoQuery;

  const data = response?.data;

  return (
    <div className={`card h-100 }`} style={{ pageBreakInside: 'avoid' }}>
      <div className="card-header pt-2">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fs-3 mb-1 fw-bold">{title}</span>
        </h3>
      </div>

      <div className="card-body py-3">
        {data !== undefined
          ? Object.entries(data).map(([key, value]: any, index: number) => (
              <div key={index}>
                <a
                  href="#"
                  className="text-dark text-hover-primary d-block mb-4 mt-2 fs-8"
                >
                  {key}
                </a>
                <div className={`progress bg-danger`}>
                  <div
                    className={`progress-bar bg-success`}
                    role="progressbar"
                    style={{ width: `${value}%` }}
                    data-aria-valuenow={parseFloat(value).toFixed(2)}
                    data-aria-valuemin="0"
                    data-aria-valuemax="100"
                  >
                    {value}%
                  </div>
                  <div
                    className={`progress-bar bg-danger`}
                    role="progressbar"
                    style={{
                      width: `${(100 - parseFloat(value)).toFixed(2)}%`,
                    }}
                    data-aria-valuenow={(100 - parseFloat(value)).toFixed(2)}
                    data-aria-valuemin="0"
                    data-aria-valuemax="100"
                  >
                    {(100 - parseFloat(value)).toFixed(2)}%
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  );
}
