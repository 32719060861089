import React from 'react';
import LoadingSpinner from '../../../sharedcomponents/LoadingSpinner';
import TextCard from './components/TextCard';
import Scores from './components/Scores';
import CitizenExperiences from './components/CitizenExperiences';

interface Props {
  month: number;
  year: string;
  OldStatisticsQuery: any;
}

export default function OldDisplay({ OldStatisticsQuery, month, year }: Props) {
  const {
    data: response,
    isLoading,
    isFetching,
    isSuccess,
  } = OldStatisticsQuery;
  const newStatsDate = new Date(2024, 10);
  const selectedDate = new Date(parseInt(year), month);

  const OldStatistics = response?.data;

  if (isLoading || isFetching) {
    return <LoadingSpinner />;
  }
  return (
    <div>
      {isSuccess && selectedDate < newStatsDate && (
        <div className="row g-5 g-xl-8">
          <div className="col-xl-6">
            <CitizenExperiences
              title="Branch Scores"
              className="mb-5 mb-xl-8"
              statistics={OldStatistics?.statistics}
            />
          </div>
          <div className="col-xl-6">
            <Scores
              title="Citizen Experiences"
              data={OldStatistics?.yesNO}
              className="mb-5 mb-xl-8"
            />
            <TextCard
              className="mb-5 mb-xl-8 w-100"
              title="Monthly Quote"
              body={OldStatistics?.recomendation ?? ''}
            />
            <TextCard
              className="mb-5 mb-xl-8 w-100"
              title="Monthly Comment"
              body={OldStatistics?.comment ?? ''}
            />
          </div>
        </div>
      )}
    </div>
  );
}
