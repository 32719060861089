import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';
import AuthLayout from '../layout/Auth/AuthLayout';

import AuthPage from '../pages/Auth/AuthPage';
import SetPassword from '../pages/SetPassword/SetPassword';

import ErrorPage from '../pages/Error/ErrorPage';

export default function PublicRoutes() {
  return (
    <Routes>
      <Route path="/auth" element={<AuthLayout />}>
        <Route path="set-password/:reset_token" element={<SetPassword />} />
        <Route path="" element={<AuthPage />} />
      </Route>
      <Route path="*" element={<Navigate to="/auth" />} />
    </Routes>
  );
}
