import { IDepartmentHead } from '../../pages/Settings/core/models';
export const AUTH_LOCAL_KEY = 'talktosema_user';
export const AUTH_LOCAL_USER = 'talktosema_user_body';

export type TFormAction = 'create' | 'edit';

export interface AuthContextType {
  token: string | null;
  user: User | null;
  login: (token: string, loggedInUser: User) => void;
  setUser: (loggedInUser: User) => void;
  logout: () => void;
}

export interface SettingContextType {
  departmentHead: IDepartmentHead;
  setDepartmentHead: (user: IDepartmentHead) => void;
}

// Add deparments to this store for create/edit user and the filters
export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  phoneNumber: string;
  department: string[];
  user_Id: string;
  admin_Account: string;
}
