type Props = {
  className: string;
  title: string;
  body: string;
};

export default function TextCard({ className, title, body }: Props) {
  return (
    <>
      <div className={`card ${className}`}>
        <div className="card-header border-0">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">{title}</span>
          </h3>
        </div>
        <div className="card-body py-3">
          <span className="text-dark text-hover-primary d-block mb-4 mt-2 fs-8">
            {body}
          </span>
        </div>
      </div>
    </>
  );
}
