import React from "react";
import StatisticCardLoader from "./StatisticCardLoader";

export default function KeyMetricsLoader() {
  return (
    <div className="px-4 key-metrics">
      <StatisticCardLoader />
      <StatisticCardLoader />
      <StatisticCardLoader />
      <StatisticCardLoader />
    </div>
  );
}
