import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikValues, ErrorMessage } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useMutation } from '@tanstack/react-query';

import { ResetPassword } from '../../apis/app.service';
import { useAuth } from '../../store/AuthProvider';
import './core/style.scss';

// import AppButton from '../sharedcomponents/Buttons/AppButton/AppButton';
import './core/style.scss';
import AppButton from '../sharedcomponents/Buttons/AppButton/AppButton';

const AuthSchema = Yup.object({
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required()
    .label('Password'),
  confirmPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required()
    .label('confirm Password'),
});
const initialValues = {
  password: '',
  confirmPassword: '',
};

export default function SetPassword() {
  const { token } = useAuth();
  const navigate = useNavigate();
  let { reset_token } = useParams();
  const [passwordView, setpasswordView] = useState(false);
  const [confirmPasswordView, setConfirmPasswordView] = useState(false);

  useEffect(() => {
    if (token) {
      navigate('/interviews', { replace: true });
    }
  }, [navigate, token]);

  const mutation = useMutation({
    mutationFn: ResetPassword,
    onSuccess: (res) => {
      const { message } = res;
      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });
      navigate('/auth', { replace: true });
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });
  const submitForm = async (values: any, actions: FormikValues) => {
    if (values.password !== values.confirmPassword) {
      toast.error('Passwords dont match', {
        duration: 3000,
        position: 'top-right',
      });
      return null;
    }
    if (reset_token) {
      mutation.mutate({ resetToken: reset_token, body: values });
    }
  };

  return (
    <div className="SetPass_container d-flex justify-content-center align-items-center">
      <div className="card SetPass_card">
        <div className="card-body">
          <h3 className="card-title text-center">Set Password</h3>
          <Formik
            validationSchema={AuthSchema}
            initialValues={initialValues}
            onSubmit={submitForm}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form className="form" noValidate>
                <div className="mb-3">
                  <label className="form-label">Password</label>
                  <div className="input-group">
                    <input
                      name="password"
                      type={passwordView ? 'text' : 'password'}
                      onChange={formik.handleChange}
                      className="form-control"
                      value={formik.values.password}
                    />
                    <span
                      className="input-group-text"
                      onClick={() => setpasswordView(!passwordView)}
                    >
                      <i className="fas fa-eye btn-icon-default"></i>
                    </span>
                  </div>

                  <div className="text-danger">
                    <ErrorMessage name="password" />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Confirm Password</label>
                  <div className="input-group">
                    <input
                      name="confirmPassword"
                      type={confirmPasswordView ? 'text' : 'password'}
                      onChange={formik.handleChange}
                      className="form-control"
                      value={formik.values.confirmPassword}
                    />
                    <span
                      className="input-group-text"
                      onClick={() => setConfirmPasswordView(!confirmPasswordView)}
                    >
                      <i className="fas fa-eye btn-icon-default"></i>
                    </span>
                  </div>

                  <div className="text-danger">
                    <ErrorMessage name="confirmPassword" />
                  </div>
                </div>
                <AppButton type="submit" text="Set Password" width="100%" btnType="primary" />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
