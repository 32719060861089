import React from 'react';
import { ErrorMessage, Form, Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { ProfileForm, UserProfileUpdate } from '../../../core/models';
import { ProfileUpdate } from '../../../../../apis/app.service';
import { useAuth } from '../../../../../store/AuthProvider';
import { User } from '../../../../../store/core/models';
import AppButton from '../../../../sharedcomponents/Buttons/AppButton/AppButton';

const ProfileSchema = Yup.object({
  firstName: Yup.string().required().label('First name'),
  lastName: Yup.string().required().label('Last name'),
  email: Yup.string().email().required().label('Email'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{9}$/, 'Phone number must be exactly 9 digits')
    .required()
    .label('Phone'),
});

let initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
};

export default function ContactInformation() {
  const { user, setUser } = useAuth();
  const updateProfile = useMutation({
    mutationFn: ProfileUpdate,
    onSuccess: (res, variable) => {
      const { message } = res;
      const { email, phoneNumber, name } = variable;
      const [firstName, lastName] = name.split(' ');

      if (user) {
        const update: User = {
          ...user,
          firstName,
          lastName: lastName || '',
          email,
          phoneNumber: phoneNumber.slice(4),
        };
        setUser(update);
      }

      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });
  const submitProfileForm = async (
    values: ProfileForm,
    actions: FormikValues
  ) => {
    const update: UserProfileUpdate = {
      name: `${values.firstName} ${values.lastName}`,
      email: values.email,
      phoneNumber: `+256${values.phoneNumber}`,
    };
    updateProfile.mutate(update);
  };

  if (user) {
    initialValues = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
    };
  }
  return (
    <div>
      <h2>General Information</h2>
      <div className="card mb-3">
        <div className="card-body">
          <h3 className="card-title">Contact Information</h3>
          <Formik
            validationSchema={ProfileSchema}
            initialValues={initialValues}
            onSubmit={submitProfileForm}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form className="form" noValidate>
                <div className="row mb-4">
                  <div className="col-md-6">
                    <label className="form-label">First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      placeholder="E.g. Sam"
                      aria-label="First name"
                      onChange={formik.handleChange}
                      value={formik.values.firstName}
                    />
                    <div className="text-danger">
                      <ErrorMessage name="firstName" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      placeholder="E.g Odang"
                      aria-label="Last name"
                      onChange={formik.handleChange}
                      value={formik.values.lastName}
                    />
                    <div className="text-danger">
                      <ErrorMessage name="lastName" />
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-6">
                    <label className="form-label">Email address</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="E.g name@example.com"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    <div className="text-danger">
                      <ErrorMessage name="email" />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label className="form-label">Phone number</label>
                    <div className="input-group">
                      <span className="input-group-text">+256</span>

                      <input
                        type="text"
                        name="phoneNumber"
                        maxLength={9}
                        className="form-control"
                        placeholder="E.g 780000000"
                        aria-label="Phone"
                        onChange={formik.handleChange}
                        value={formik.values.phoneNumber}
                      />
                    </div>
                    <div className="text-danger">
                      <ErrorMessage name="phoneNumber" />
                    </div>
                  </div>
                </div>
                <div className="card-footer text-md-end">
                  <AppButton type="submit" text="Save" btnType="primary" />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
