import React from 'react';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

// import samplePDF from './test.pdf';
interface Props {
  fileUrl: string;
}
export default function PdfViewer({ fileUrl }: Props) {
  return (
    <div className="modal fade" id="kt_modal_preview_report" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '900px' }}>
        <div className="modal-content">
          <div className="m-4">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <div
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.3)',
                  height: '700px',
                }}
              >
                <Viewer fileUrl={fileUrl} defaultScale={SpecialZoomLevel.PageFit} />
              </div>
            </Worker>
          </div>
        </div>
      </div>
    </div>
  );
}
