import React, { FC, useState } from 'react';
import { DatePicker, Dropdown, MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(quarterOfYear);

const { RangePicker } = DatePicker;

const DeviceDatePicker: FC<React.PropsWithChildren<any>> = ({
  selectedDate,
  setSelectedDate,
  selectedDateFormatted,
  setSelectedDateFormatted,
  type,
  setType,
  setDateQuery,
  isFetching,
}) => {
  const [visible, setVisible] = useState(false);
  const typeCapitalize = type.charAt(0).toUpperCase() + type.slice(1);

  const handleDayDateChange = (date: any, dateString: any) => {
    if (date) {
      setType('day');
      setVisible(false);
      setSelectedDateFormatted(dateString);
      setSelectedDate(date);
      const start = dayjs(date.toDate()).startOf('day');
      const end = dayjs(date.toDate()).endOf('day');
      const timeline = dayjs(date).isSame(dayjs(), 'day')
        ? ['Today', 'Yesterday']
        : ['This day', 'Past day'];
      setDateQuery({
        currentStartDate: start.format('YYYY-MM-DD HH:mm:ss'),
        currentEndDate: end.format('YYYY-MM-DD HH:mm:ss'),
        previousStartDate: start
          .subtract(1, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        previousEndDate: end.subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
        timeline,
      });
    }
  };

  const handleWeekDateChange = (date: any, dateString: any) => {
    if (date) {
      setSelectedDateFormatted(dateString);
      setSelectedDate(date);
      setType('week');
      setVisible(false);

      const start = dayjs(date.toDate()).startOf('week');
      const end = dayjs(date.toDate()).endOf('week');
      setDateQuery({
        currentStartDate: start.format('YYYY-MM-DD HH:mm:ss'),
        currentEndDate: end.format('YYYY-MM-DD HH:mm:ss'),
        previousStartDate: start
          .subtract(1, 'weeks')
          .format('YYYY-MM-DD HH:mm:ss'),
        previousEndDate: end.subtract(1, 'weeks').format('YYYY-MM-DD HH:mm:ss'),
        timeline: ['This week', 'Last week'],
      });
    }
  };

  const handleMonthDateChange = (date: any, dateString: any) => {
    // Check if both dates are selected
    if (date) {
      setSelectedDateFormatted(dateString);
      setSelectedDate(date);
      setType('month');
      setVisible(false);

      const start = dayjs(date.toDate()).startOf('month');
      const end = dayjs(date.toDate()).endOf('month');
      setDateQuery({
        currentStartDate: start.format('YYYY-MM-DD HH:mm:ss'),
        currentEndDate: end.format('YYYY-MM-DD HH:mm:ss'),
        previousStartDate: start
          .subtract(1, 'months')
          .format('YYYY-MM-DD HH:mm:ss'),
        previousEndDate: end
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD HH:mm:ss'),
        timeline: ['This month', 'Last month'],
      });
    }
  };

  const handleQuarterDateChange = (date: any, dateString: any) => {
    if (date) {
      setSelectedDateFormatted(dateString);
      setSelectedDate(date);
      setType('quarter');
      setVisible(false);

      const start = dayjs(date.toDate()).startOf('quarter');
      const end = dayjs(date.toDate()).endOf('quarter');
      setDateQuery({
        currentStartDate: start.format('YYYY-MM-DD HH:mm:ss'),
        currentEndDate: end.format('YYYY-MM-DD HH:mm:ss'),
        previousStartDate: start
          .subtract(1, 'quarters')
          .format('YYYY-MM-DD HH:mm:ss'),
        previousEndDate: end
          .subtract(1, 'quarters')
          .endOf('quarter')
          .format('YYYY-MM-DD HH:mm:ss'),
        timeline: ['This quarter', 'Last quarter'],
      });
    }
  };

  const handleYearDateChange = (date: any, dateString: any) => {
    // Check if both dates are selected
    if (date) {
      setSelectedDateFormatted(dateString);
      setSelectedDate(date);
      setType('year');
      setVisible(false);

      const start = dayjs(date.toDate()).startOf('year');
      const end = dayjs(date.toDate()).endOf('year');

      setDateQuery({
        currentStartDate: start.format('YYYY-MM-DD HH:mm:ss'),
        currentEndDate: end.format('YYYY-MM-DD HH:mm:ss'),
        previousStartDate: start
          .subtract(1, 'years')
          .format('YYYY-MM-DD HH:mm:ss'),
        previousEndDate: end.subtract(1, 'years').format('YYYY-MM-DD HH:mm:ss'),
        timeline: ['This year', 'Last year'],
      });
    }
  };

  const handleDateRangeChange = (dates: any, dateStrings: any) => {
    // Check if both dates are selected

    if (
      dates &&
      dates[0] &&
      dates[1] &&
      (selectedDate[0] !== dates[0] || selectedDate[1] !== dates[1])
    ) {
      setSelectedDate(dates);
      setSelectedDateFormatted(dateStrings.join(' to '));
      setType('Custom');
      setVisible(false);

      const start = dayjs(dates[0].toDate()).startOf('day');
      const end = dayjs(dates[1].toDate()).endOf('day');
      const diff = end.diff(start, 'days');
      setDateQuery({
        currentStartDate: start.format('YYYY-MM-DD HH:mm:ss'),
        currentEndDate: end.format('YYYY-MM-DD HH:mm:ss'),
        previousStartDate: start
          .subtract(diff + 1, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        previousEndDate: end
          .subtract(diff + 1, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        timeline: ['This period', 'Last period'],
      });
    }
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className="row">
          <h5 className="col-3 d-flex align-items-center">Daily:</h5>
          <div className="col-9" onClick={(e) => e.stopPropagation()}>
            <DatePicker
              className="w-100"
              value={type === 'day' ? selectedDate : null}
              onChange={handleDayDateChange}
              disabledDate={(current) => current > dayjs().endOf('day')}
            />
          </div>
        </div>
      ),
    },

    {
      key: '2',
      label: (
        <div className="row">
          <h5 className="col-3 d-flex align-items-center">Weekly:</h5>
          <div className="col-9" onClick={(e) => e.stopPropagation()}>
            <DatePicker
              className="w-100"
              picker="week"
              value={type === 'week' ? selectedDate : null}
              onChange={handleWeekDateChange}
              disabledDate={(current) => current > dayjs().endOf('week')}
            />
          </div>
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div className="row">
          <h5 className="col-3 d-flex align-items-center">Monthly:</h5>
          <div className="col-9" onClick={(e) => e.stopPropagation()}>
            <DatePicker
              className="w-100"
              picker="month"
              value={type === 'month' ? selectedDate : null}
              onChange={handleMonthDateChange}
              disabledDate={(current) => current > dayjs().endOf('month')}
            />
          </div>
        </div>
      ),
    },
    {
      key: '4',
      label: (
        <div className="row">
          <h5 className="col-3 d-flex align-items-center">Quarterly:</h5>
          <div className="col-9" onClick={(e) => e.stopPropagation()}>
            <DatePicker
              className="w-100"
              picker="quarter"
              value={type === 'quarter' ? selectedDate : null}
              onChange={handleQuarterDateChange}
              disabledDate={(current) => current > dayjs().endOf('quarter')}
            />
          </div>
        </div>
      ),
    },
    {
      key: '5',
      label: (
        <div className="row">
          <h5 className="col-3 d-flex align-items-center">Yearly:</h5>
          <div className="col-9" onClick={(e) => e.stopPropagation()}>
            <DatePicker
              className="w-100"
              picker="year"
              value={type === 'year' ? selectedDate : null}
              onChange={handleYearDateChange}
              disabledDate={(current) => current > dayjs().endOf('year')}
            />
          </div>
        </div>
      ),
    },
    {
      key: '6',
      label: (
        <div className="row">
          <h5 className="col-3 d-flex align-items-center">Custom:</h5>
          <div className="col-9" onClick={(e) => e.stopPropagation()}>
            <RangePicker
              className="w-100"
              value={type === 'Custom' ? selectedDate : null}
              onCalendarChange={handleDateRangeChange}
              disabledDate={(current) => current > dayjs().endOf('day')}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Dropdown
      menu={{ items, selectable: true }}
      trigger={['click']}
      placement="bottomLeft"
      open={visible}
      disabled={isFetching}
      onOpenChange={(v) => setVisible(v)}
    >
      <div
        className="d-flex align-items-center mr-4"
        style={{
          height: '37px',
          backgroundColor: !isFetching ? '#fff' : '#e9ecef',
          color: '#5e6278',
          border: '1px solid #dee2e6',
          borderRadius: '0.475rem',
          fontWeight: 500,
          fontSize: '1.1rem',
          padding: '0.75rem 1rem 0.75rem 1rem',
        }}
      >
        {typeCapitalize} : {selectedDateFormatted}
        <DownOutlined className="ml-4" />
      </div>
    </Dropdown>
  );
};

export { DeviceDatePicker };
