import React from "react";
import ChartLoader from "./ChartLoader";

export default function ChartMetricsLoader() {
  return (
    <div className="px-4 chart-metrics">
      <ChartLoader />
      <ChartLoader />
    </div>
  );
}
