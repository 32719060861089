import React, { useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import AppButton from '../../../../sharedcomponents/Buttons/AppButton/AppButton';
import { deleteUser } from '../../../../../apis/app.service';
import { useSettingPage } from '../../../../../store/SettingsProvider';

export default function DeleteDepartmentHead() {
  const queryClient = useQueryClient();
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const { departmentHead } = useSettingPage();
  const { data } = departmentHead;

  const mutation = useMutation({
    mutationFn: deleteUser,
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries({
        queryKey: ['DepartmentHeadList'],
      });
      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });

      if (closeButtonRef.current) {
        closeButtonRef.current.click();
      }
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });

  const handleDelete = (userId: string) => {
    mutation.mutate(userId);
  };
  return (
    <div
      className="modal fade"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      id="deleteDepartmentHead"
      aria-labelledby="deleteDepartmentHead"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Delete Account
            </h1>
            <button
              type="button"
              data-bs-dismiss="modal"
              ref={closeButtonRef}
              className="d-none"
            ></button>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <span>Are you sure you want to delete {data.name}?</span>
          </div>
          <div className="modal-footer">
            <AppButton
              text="cancel"
              btnType="secondary"
              data-bs-dismiss="modal"
            />
            <AppButton
              text="delete"
              btnType="danger"
              type="submit"
              onClick={() => {
                if (data.id) {
                  handleDelete(data.id);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
