/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import MUIDataTable, {
  MUIDataTableMeta,
  MUIDataTableOptions,
} from 'mui-datatables';
import LoadingSpinner from '../sharedcomponents/LoadingSpinner';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import PdfViewer from './components/pdfviewer';

import { getReportData } from '../../apis/app.service';
import { useAuth } from '../../store/AuthProvider';

const ReportsTableComponent = () => {
  const { user } = useAuth();
  const [fileUrl, setFileUrl] = useState('');
  const columns = [
    {
      name: 'name',
      label: 'Report Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'month',
      label: 'Month',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'year',
      label: 'Year',
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: '',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: false,
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          return (
            <>
              {/* begin::Menu */}
              <div
                className="d-flex flex-row align-content-center w-125px py-2"
                data-kt-menu="true"
              >
                {/* begin::Menu item */}
                <span
                  className="btn-icon-default pe-5 icon-color"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_preview_report"
                  onClick={() =>
                    setFileUrl(
                      tableMeta.tableData[tableMeta.rowIndex].download_link
                    )
                  }
                >
                  <i
                    className="fas fa-eye btn-icon-default"
                    data-bs-toggle="tooltip"
                    title="preview"
                  ></i>
                </span>
                {/* end::Menu item */}
                <span
                  className="btn-icon-default icon-color"
                  onClick={() =>
                    downloadFile(
                      tableMeta.tableData[tableMeta.rowIndex].download_link,
                      tableMeta.tableData[tableMeta.rowIndex].name
                    )
                  }
                >
                  <i
                    className="fas fa-download btn-icon-default"
                    data-bs-toggle="tooltip"
                    title="Download"
                  ></i>
                </span>
                {/* end::Menu item */}
              </div>
              {/* end::Menu */}
            </>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    filterType: 'dropdown',
    viewColumns: false,
    responsive: 'standard',
    jumpToPage: true,
    downloadOptions: { filename: 'sema_reports.csv', separator: ',' },
    elevation: 1,
    customSearchRender: (searchText, handleSearch) => {
      return (
        <div className="card-title">
          <div className="d-flex align-items-center position-relative my-1">
            <input
              type="text"
              data-kt-report-table-filter="search"
              className="form-control form-control-solid ps-14 mt-3"
              style={{ width: '250px' }}
              placeholder="Search Report"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
        </div>
      );
    },
  };

  const { data, isFetching, isError, error } = useQuery({
    queryKey: ['reports'],
    queryFn: () => getReportData({ recipientIds: user!.user_Id }),
  });

  const downloadFile = (downloadLink: string, name: string) => {
    fetch(downloadLink, {
      method: 'GET',
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${name}.pdf`;
        a.click();
      });
    });
  };

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return (
      <div className="w-75 mr-auto">
        <p>Oops! Something Went Wrong! Try Again!!!</p>
        <p>{error.message}</p>
      </div>
    );
  }

  return (
    <>
      <MUIDataTable
        title={'Reports'}
        data={data}
        columns={columns}
        options={options}
      />
      <PdfViewer fileUrl={fileUrl} />
    </>
  );
};

export default ReportsTableComponent;
