import { toAbsoluteUrl } from "../../../../helpers/AssetHelpers";
import KeyMetricsLoader from "./components/Loader/KeyMetricsLoader";
import StatisticCard from "./components/StatisticCard";

interface Props {
  cardStatisticsQeury: any;
}
export default function KeyMetrics({ cardStatisticsQeury }: Props) {
  const {
    data: response,
    isLoading,
    isFetching,
    isSuccess,
  } = cardStatisticsQeury;

  const cardStatistics = response?.data;

  if (isLoading || isFetching) {
    return <KeyMetricsLoader />;
  }

  return (
    <div>
      {isSuccess ? (
        <div className="px-4 key-metrics">
          {cardStatistics?.["Average-satisfaction"] ? (
            <StatisticCard
              title="Average satisfaction rating"
              color="#7FBB48"
              stat={`${cardStatistics["Average-satisfaction"]} %`}
              icon={
                <img
                  src={toAbsoluteUrl("media/icons/satisfaction.svg")}
                  alt="SEMA logo"
                  className="align-self-center mb-2"
                  height="40px"
                />
              }
            />
          ) : null}

          {cardStatistics?.["Responsiveness"] ? (
            <StatisticCard
              title="Responsiveness"
              color="#D7DA00"
              stat={`${cardStatistics["Responsiveness"]} %`}
              icon={
                <img
                  src={toAbsoluteUrl("media/icons/responsiveness.svg")}
                  alt="SEMA logo"
                  className="align-self-center mb-2"
                  height="40px"
                />
              }
            />
          ) : null}

          {cardStatistics?.["Count"] ? (
            <StatisticCard
              title="Interviwed Citizens"
              color="#9FA3FF"
              stat={`${cardStatistics["Count"]}`}
              icon={
                <img
                  src={toAbsoluteUrl("media/icons/interviewedCitizens.svg")}
                  alt="SEMA logo"
                  className="align-self-center mb-2"
                  height="40px"
                />
              }
            />
          ) : null}

          {cardStatistics?.["Compliment"] ? (
            <StatisticCard
              title="Compliment"
              color="#05893E"
              stat={`${cardStatistics["Compliment"]} %`}
              icon={
                <img
                  src={toAbsoluteUrl("media/icons/compliment.svg")}
                  alt="SEMA logo"
                  className="align-self-center mb-2"
                  height="40px"
                />
              }
            />
          ) : null}
          {cardStatistics?.["Average-waiting-time"] ? (
            <StatisticCard
              title="Average waiting time"
              color="#59676A"
              stat={`${cardStatistics["Average-waiting-time"]} mins`}
              icon={
                <img
                  src={toAbsoluteUrl("media/icons/waitingTime.svg")}
                  alt="SEMA logo"
                  className="align-self-center mb-2"
                  height="40px"
                />
              }
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
