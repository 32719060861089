import { Skeleton } from "antd";
import React from "react";

export default function TextCardLoader() {
  return (
    <div>
      <div className="card h-100">
        <div className="card-header pt-2">
          <h3 className="card-title align-items-start flex-column">
            <Skeleton.Button active={true} />
          </h3>
        </div>
        <div
          className="card-body d-flex align-items-center justify-content-between"
          style={{ columnGap: "1rem" }}
        >
          <Skeleton active={true} avatar paragraph={{ rows: 3 }} />
        </div>
      </div>
    </div>
  );
}
