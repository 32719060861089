/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

type Props = {
  className: string;
  title: string;
  data: Object | undefined;
};

export default function Scores({ className, title, data }: Props) {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">{title}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {data !== undefined
          ? Object.entries(data).map(([key, value]) => (
              <div>
                <span className="text-dark text-hover-primary d-block mb-4 mt-2 fs-8">
                  {key}
                </span>
                <div className={`progress bg-danger`}>
                  <div
                    className={`progress-bar bg-success`}
                    role="progressbar"
                    style={{ width: value }}
                    data-aria-valuenow={parseFloat(value)}
                    data-aria-valuemin="0"
                    data-aria-valuemax="100"
                  >
                    {' '}
                    {value}
                  </div>
                  <div
                    className={`progress-bar bg-danger`}
                    role="progressbar"
                    style={{
                      width: `${(100 - parseFloat(value)).toFixed(2)}%`,
                    }}
                    data-aria-valuenow={(100 - parseFloat(value)).toFixed(2)}
                    data-aria-valuemin="0"
                    data-aria-valuemax="100"
                  >
                    {(100 - parseFloat(value)).toFixed(2)}%
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>
      {/* begin::Body */}
    </div>
  );
}
